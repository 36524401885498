<template>
  <v-container>
    <p class="long-text">Dernière mise à jour : 20/03/2023</p>
    <h1>Règlement</h1>
    <div class="text-justify long-text mb-15">
      <v-row>
        <v-col cols="12">
          <h2>Introduction</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="long-text">
          <p class="long-text">Des idées, toute personne qui écrit régulièrement en a. Malheureusement, nombreux sont les projets ambitieux commencés et jamais terminés.</p>
          <p class="long-text">Néanmoins, la régularité dans l'écriture est primordiale pour entretenir la flamme de la créativité.</p>
          <p class="long-text">La solution ?</p>
          <p class="long-text">Le concours d'écriture de la Taverne des Spores !</p>
          <p class="long-text"></p>
          <p class="long-text"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Spores ?</h3>
          <p class="long-text">Une Spore est un texte court de 600 signes maximum, espaces et titre compris.</p>
          <p class="long-text">Ce format ultra court permet à l'auteur d'aller droit au but, tout en conservant l'impact de l'idée originale.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Concours ?</h3>
          <p class="long-text">Pour valider son concept, tester ses compétences ou tout simplement pour le plaisir, il est primordial de faire lire son texte à d'autres personnes ne connaissant pas forcément votre univers.</p>
          <p class="long-text">Chaque mois, la taverne des Spores organise un concours récompensant les meilleures Spores soumises par tout utilisateur inscrit au site.</p>
          <p class="long-text">Après délibération, les meilleures Spores sont choisies par le jury et publiées sur la page de résultat du concours.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2>Règlement</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Définition d'une Spore</h3>
          <p class="long-text">Une Spore est un texte court de 600 signes maximum, espaces et titre compris.</p>
          <p class="long-text">Pour être valide, une Spore devra être soumise par le biais du formulaire mis à disposition sur le site et accessible à toute personne disposant d'un compte et connectée.</p>
          <p class="long-text">Toute Spore transmise par tout autre moyen sera considérée comme nulle.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Participation</h3>
          <p class="long-text">Toute personne disposant d'un accès internet peut participer au concours.</p>
          <p class="long-text">La participation au concours est totalement gratuite et n'entraîne aucuns frais cachés.</p>
          <p class="long-text">Les auteurs s'engagent à ne soumettre que des textes écrits par eux, ou à défaut d'en posséder les droits exclusifs.</p>
          <p class="long-text">Chaque auteur est libre de soumettre au maximum 3 textes pour chaque concours. Chaque texte devra être inédit.</p>
          <p class="long-text">En soumettant un texte au concours, l'auteur s'engage à laisser la Taverne des Spores libre de toute utilisation de son texte, commerciale ou non.</p>
          <p class="long-text">A son tour, la Taverne des Spores s'engage à ne jamais utiliser le texte sans citer le nom de l'auteur, et à n'y apporter aucune modification sans accord préalable de l'auteur (hormis corrections éventuelles).</p>
          <p class="long-text"><strong class="long-text font-weight-black">Le texte restera la propriété de son auteur.</strong></p>
          <p class="long-text">Les textes à caractère raciste, sexiste, diffamatoire ou tout simplement jugés irrecevables par le jury seront retirés de la compétition sans préavis.</p>
          <p class="long-text">De même, le jury est souverain dans ses décisions et ne pourra être remis en cause par aucun des participants.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Délibération</h3>
          <p class="long-text">La totalité des textes soumis pour le concours sera délivrée à chacun des membres du jury de façon anonyme.</p>
          <p class="long-text">Chaque juré votera pour ses Spores préférées selon son intime conviction.</p>
          <p class="long-text">Le système de notation est le suivant :</p>
          <p class="long-text">
            <ul>
              <li class="long-text ml-15">Chaque Spore se voit attribuée un poids, allant de 0 à 5. Entendons-nous bien : 0 ne veut pas dire nul, mais simplement que le juré estime que celle-ci ne se démarque pas des autres :)</li>
              <li class="long-text ml-15">Chaque Spore obtient donc un poids global en fonction des votes des jurés.</li>
              <li class="long-text ml-15">Les Spores sont classées par poids décroissant, donnant le classement provisoire.</li>
              <li class="long-text ml-15">Le jury se réunit pour départager les Spores ayant le même poids ou débattre sur le classement provisoire.</li>
              <li class="long-text ml-15">Le classement définitif est voté à la fin de cette réunion.</li>
            </ul>
          </p>
          <p class="long-text">Les résultats seront communiqués par le biais du site, dans la section résultats.</p>
          <p class="long-text">La Spore arrivant première se verra attribuer le titre de Spore d'Or. Son auteur recevra la récompense le cas échéant sous réserve d'avoir fourni une adresse mail valide. Son auteur recevra également 10 points.</p>
          <p class="long-text">La Spore arrivant seconde se verra attribuer le titre de Spore d'Argent. Son auteur recevra 8 points.</p>
          <p class="long-text">La Spore arrivant troisième se verra attribuer le titre de Spore de Bronze. Son auteur recevra 6 points.</p>
          <p class="long-text">L'auteur de la Spore arrivant quatrième se verra attribuer 4 points.</p>
          <p class="long-text">Les auteurs des Spores arrivant cinquième et sixième se verront attribuer 3 points.</p>
          <p class="long-text">Les auteurs des Spores arrivant septième et huitième se verront attribuer 2 points.</p>
          <p class="long-text">Les auteurs des deux dernières Spores du top 10 gagneront 1 point.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Récompense</h3>
          <p class="long-text">Si non précisée lors du lancement du concours, la seule récompense pour avoir participé (et gagné le cas échéant) est la gloire et le réconfort du travail bien fait.</p>
          <p class="long-text">En aucun cas l'auteur ne pourra réclamer ou exiger une récompense qui n'aurait pas été annoncée au lancement du concours.</p>
          <p class="long-text">Parallèlement, les auteurs entrant dans le top 10 du concours se verront remettre des points selon l'échelle définie au paragraphe précédent. Ces points n'ont pour l'instant aucune autre utilité que de réaliser un classement annuel des auteurs qui sera publié sur le site. Cela pourra évoluer par la suite.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Déroulement des concours</h3>
          <p class="long-text">Chaque début de mois, un nouveau concours est lancé.</p>
          <p class="long-text">Les concours ne sont généralement soumis à aucune restriction de thème, ni de genre. Si toutefois cela était le cas, le thème ou le genre imposé serait spécifié dans les règles du concours visibles sur le formulaire de soumission. </p>
          <p class="long-text">Les auteurs ont le mois complet pour soumettre leurs textes. Le jury délibérera durant les quinze jours suivants la fin du mois, pour une annonce des résultats les jours suivants.</p>
          <p class="long-text">Ces délais/périodes pourront être ajustés par concours suivant la fréquentation du site et le taux de participation.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Premium</h3>
          <p class="long-text">La possibilité est offerte aux auteurs souhaitant soutenir la Taverne de souscrire à un abonnement premium.</p>
          <p class="long-text">Le contenu de cet abonnement premium est détaillé sur <router-link to="/infos-premium" class="long-text">cette page.</router-link></p>
          <p class="long-text">Conformément aux dispositions de l’article L. 121-21-8 du Code de la Consommation, l’Abonné reconnaît et accepte expressément que la fourniture du Service d'abonnement Premium de la Taverne des Spores commence immédiatement après la validation de son abonnement, soit avant la fin du délai de quatorze jours francs prévu par le Code de la Consommation. Il reconnaît et accepte en conséquence de ne pas bénéficier du droit de rétractation lié à la vente en ligne ou à distance. En conséquence, aucune demande de rétractation, d’annulation ou de remboursement ne sera recevable pour la période souscrite.</p>
          <p class="long-text">L'Abonné peut modifier ou annuler son abonnement via la page prévue à cet effet accessible depuis son profil.</p>
          <p class="long-text">Une annulation d' abonnement entraîne la suspension immédiate de l'accès aux fonctionnalités Premium.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Conclusion</h3>
          <p class="long-text">La Taverne des Spores se réserve le droit de modifier ce règlement sans préavis à tout moment.</p>
          <p class="long-text">Toute inscription et/ou participation au concours implique l'acceptation pleine et entière du présent règlement.</p>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    news: null
  }),
  mounted() {

  },
  methods: {

  }
};
</script>
<style scoped>
.img-news {
  margin: auto;
  max-width: 500px;
}
</style>