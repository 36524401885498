<template>
  <v-container v-if="contests">
    <v-row>
      <v-col cols="12">
        <h1>Archives</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="long-text">Vous pouvez accéder ici aux palmarès des concours précédents.</p>
      </v-col>
    </v-row>
    <v-row v-show="!$store.state.isPremium">
      <v-card elevation="24" class="w-100 mb-5 pa-2">
        <v-col cols="12">
          <h3 class="text-center">Envie de lire toutes les Spores du concours ? Pensez à la Taverne Premium !</h3>
          <p class="text-center">C'est 2 euros seulement, mais c'est une grande aide pour la Taverne ;)</p>
          <p class="text-center" v-show="$store.state.isConnected"><v-btn class="bkg-orange" large to="/profil"><span class="color-white">S'abonner</span></v-btn></p>
        </v-col>
      </v-card>
    </v-row>
    <v-row>
      <v-col
          v-for="(contest, index) in contests"
          :key="contest.name"
          :cols="index === 0 ? 12 : 6"
      >
        <v-card :to="'/palmares/' + contest.id">
          <v-img
              :src="'https://tavernedesspores.fr/img/' + contest.img"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
          >
            <v-card-title v-html="contest.name" class="contest-name font-weight-thin font-italic"></v-card-title>
            <v-card-subtitle v-html="contest.theme" class="contest-theme"></v-card-subtitle>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    contests: null
  }),
  mounted() {
    this.$http.get('/getArchiveContests').then(response => {
      this.contests = response.data;
    }).catch(() => {

    });
  },
  methods: {

  }
};
</script>
<style scoped>
.contest-name {
  color: white;
}
.contest-theme {
  color: white !important;
  font-size: 25px;
}
</style>