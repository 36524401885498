<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Soumettre une Spore</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet
            :elevation="24"
            class="mx-auto pa-5"
            color="blue lighten-4"
        >
        <ul>
          <li>Soumettez ici votre Spore pour le concours actuel.</li>
          <li>Veillez à votre orthographe !</li>
          <li>Titre ET texte obligatoire.</li>
          <li>Vous pouvez soumettre <strong>3 Spores maximum</strong> pour un concours.</li>
          <li>Chaque Spore doit être inédite, et vous devez en posséder les droits.</li>
          <li><strong>En cas de copier/coller depuis votre éditeur de texte (Word ou autre), préférez le "coller" en texte brut (clic droit dans le champ, puis Coller en texte brut).</strong></li>
          <li><strong>Pensez à sauvegarder vos Spores ailleurs que sur la Taverne :)</strong></li>
          <li>Si vous rencontrez le moindre problème, n'hésitez pas à nous contacter.</li>
        </ul>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label>Entrez le titre de votre Spore :</label>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
            v-model="spore.title"
            label="Titre de la spore"
            required
            outlined
            solo
            @keyup="updateChar()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label>Entrez le texte de votre Spore :</label>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
            label="Texte de la Spore"
            v-model="spore.text"
            outlined
            solo
            rows="10"
            background-color="light-blue"
            @keyup="updateChar()"
        ></v-textarea>
        <p><label>Caractères restants : <span :class="{'limit-error': nb_char_left<=0, 'limit-warning': nb_char_left<40 && nb_char_left>0}">{{ nb_char_left }}</span></label></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn large class="bkg-orange"><span class="color-white" @click="send()">Envoyer</span></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    spore: {
      'title': "",
      'text': ""
    },
    nb_char_left: 600,
    nb_char_max: 600
  }),
  mounted() {

  },
  methods: {
    updateChar: function() {
      this.nb_char_left = this.nb_char_max - (this.spore.title.replace(/(\r\n|\n|\r)/gm,"").length + this.spore.text.replace(/(\r\n|\n|\r)/gm,"").length);
    },
    send: function() {
      if(this.nb_char_left >= 0) {
        if (confirm("ATTENTION : Une fois soumise, votre Spore n'est plus éditable. Souhaitez-vous confirmer son envoi ?")) {
          this.$http.post( '/sendSpore',
              this.spore
          ).then(response => {
            if(response.data.success) {
              this.$notify({
                group: 'spore',
                title: 'OK',
                text: response.data.message,
                type: 'success'
              });
              this.spore = {
                'title': "",
                'text': ""
              };
            } else {
              this.$notify({
                group: 'spore',
                title: 'Erreur...',
                text: response.data.message,
                type: 'error'
              });
            }
          }).catch(function(response){
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: response.data.message,
              type: 'error'
            });
          });
        }
      } else {
        this.$notify({
          group: 'spore',
          title: 'Erreur...',
          text: 'Désolé, une spore doit faire 600 caractères MAXIMUM. Espaces compris. :(',
          type: 'error'
        });
      }
    }
  }
};
</script>
<style scoped>
label {
  font-weight: bolder;
}
.limit-error {
  color: #ff0000;
}
.limit-warning {
  color: #fd5427;
}
.v-application .primary--text {
  caret-color: red !important;
}
</style>