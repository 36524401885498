import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isConnected:false,
        isPremium:false
    },
    getters: {
        isAuthenticated: state => state.isConnected,
        isPremium: state => state.isPremium
    },
    mutations: {
        connect (state) {
            state.isConnected = true;
        },
        disconnect (state) {
            state.isConnected = false;
        },
        setPremium (state) {
            state.isPremium = true;
        },
        unsetPremium (state) {
            state.isPremium = false;
        }
    },
    actions: {
    },
    modules: {
    }
})
