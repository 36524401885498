<template>
  <v-container v-if="news">
    <p>{{ news.publication_date }}</p>
    <h1>{{ news.title }}</h1>
    <v-img :src="'https://tavernedesspores.fr/img/' + news.image" class="img-news my-5"></v-img>
    <v-divider class="my-5"></v-divider>
    <div class="text-justify long-text mb-15" v-html="news.text"></div>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    news: null
  }),
  mounted() {
    this.$http.get('/getNews/' + this.$route.params.id).then(response => {
      this.news = response.data;
    }).catch(() => {

    });
  },
  methods: {

  }
};
</script>
<style scoped>
.img-news {
  margin: auto;
  max-width: 500px;
}
</style>