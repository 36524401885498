import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import TwitterFeed from "vuejs-twitter-feed";
import Notifications from 'vue-notification';

Vue.use(TwitterFeed);
Vue.use(Notifications);

Vue.config.productionTip = false

const base = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://tavernedesspores.fr/server/' : 'https://tavernedesspores.fr/server2/',
  crossDomain: true
});

Vue.prototype.$http = base;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
